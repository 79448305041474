import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40')
];

export const server_loads = [2,4,5];

export const dictionary = {
		"/(desktop)": [~6,[2]],
		"/(desktop)/blog/[id]": [7,[2]],
		"/(desktop)/contact": [8,[2]],
		"/(desktop)/licenties": [9,[2]],
		"/(desktop)/mijn": [~10,[2,3]],
		"/(desktop)/mijn/admin": [~14,[2,3]],
		"/(desktop)/mijn/admin/(protected)/bestellingen": [~15,[2,3,4]],
		"/(desktop)/mijn/admin/(protected)/gebruikers": [~16,[2,3,4]],
		"/(desktop)/mijn/admin/(protected)/gebruikers/[id]": [~17,[2,3,4]],
		"/(desktop)/mijn/admin/(protected)/repeatthebeat": [~18,[2,3,4]],
		"/(desktop)/mijn/admin/(protected)/ruben": [~19,[2,3,4]],
		"/(desktop)/mijn/admin/(protected)/stemlijsten": [~20,[2,3,4]],
		"/(desktop)/mijn/admin/(protected)/stemlijsten/[id]": [~21,[2,3,4]],
		"/(desktop)/mijn/admin/(protected)/suggesties": [~22,[2,3,4]],
		"/(desktop)/mijn/app/cadeaubonnen": [~23,[2,3,5]],
		"/(desktop)/mijn/app/dashboard": [~24,[2,3,5]],
		"/(desktop)/mijn/app/djs": [~25,[2,3,5]],
		"/(desktop)/mijn/app/instellingen": [~26,[2,3,5]],
		"/(desktop)/mijn/app/points": [~27,[2,3,5]],
		"/(desktop)/mijn/app/premium": [~28,[2,3,5]],
		"/(desktop)/mijn/app/repeatthebeat": [~29,[2,3,5]],
		"/(desktop)/mijn/app/shop": [~30,[2,3,5]],
		"/(desktop)/mijn/app/stemlijst": [~31,[2,3,5]],
		"/(desktop)/mijn/app/top20": [~32,[2,3,5]],
		"/(desktop)/mijn/(auth)/inloggen": [~11,[2,3]],
		"/(desktop)/mijn/(auth)/registreren": [12,[2,3]],
		"/(desktop)/mijn/(auth)/wachtwoord-vergeten": [13,[2,3]],
		"/privacy": [38],
		"/(desktop)/programma": [33,[2]],
		"/stemlijst-voor-dj": [39],
		"/stemlijst-voor-dj/[id]": [~40],
		"/(desktop)/stemlijst": [~34,[2]],
		"/(desktop)/studio": [35,[2]],
		"/(desktop)/top20": [36,[2]],
		"/(desktop)/vacatures": [37,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';